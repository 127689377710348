import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import React from "react";


export const ConfirmDialog = (props: {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Dialog
      open={props.show}
      onClose={props.onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Bekräfta</DialogTitle>
      <DialogContent>Är du säker på att du vill ta bort?</DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Avbryt
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          Ta bort
        </Button>
      </DialogActions>
    </Dialog>
  );
};
