import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { RpcInvocation, useRpcFetch } from "cooke-rpc-react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { generatePath, useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "./ConfirmDialog";
import FetchStatus from "./components/FetchStatus";
import { User, adminUserController } from "./generated/rpc";
import { routes } from "./routes";
import { Email } from "./types";
import { useErrorDispatcher } from "./utils";
import { parseTimestamp } from "./utils/parseTimestamp";

export function UserView() {
  const match = useRouteMatch<any>(routes.user)!;
  const userId = parseInt(match.params.userId);
  const wordQuery = useRpcFetch(adminUserController.getUser, userId);

  if (wordQuery.result === undefined) {
    return <FetchStatus query={wordQuery} />;
  }

  const user = wordQuery.result;

  if (user === null) {
    return <>Användaren finns inte</>;
  }

  return <UserForm initUser={user} />;
}

const UserForm = ({ initUser }: { initUser: User }) => {
  const wordId = initUser.id;

  const [user, setUser] = useState(initUser);
  useEffect(() => {
    setUser(initUser);
  }, [initUser]);

  const { dispatch, error } = useErrorDispatcher();

  const useUpdateCallback = <TState extends any[]>(
    stateCb: (...val: TState) => void,
    invoke: (...val: TState) => RpcInvocation<any>
  ) => {
    const debouncedInvoke = debounce((...val: TState) => dispatch(invoke(...val)), 500);
    return useCallback(
      (...val: TState) => {
        stateCb(...val);
        debouncedInvoke(...val);
      },
      [wordId]
    );
  };

  const updateAccessUntil = useUpdateCallback(
    (accessUntil) => setUser((p) => ({ ...p, accessUntil })),
    (accessUntil: string) => adminUserController.updateUser(user.id, { accessUntil })
  );

  const updateEmail = useUpdateCallback(
    (email) => setUser((p) => ({ ...p, email })),
    (email: string) => adminUserController.updateUser(user.id, { email: email as Email })
  );

  const updateIsAdmin = useUpdateCallback(
    (isAdmin) => setUser((p) => ({ ...p, isAdmin })),
    (isAdmin: boolean) => adminUserController.updateUser(user.id, { isAdmin })
  );

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const history = useHistory();

  return (
    <>
      <ConfirmDialog
        onCancel={() => setShowConfirmRemove(false)}
        onConfirm={async () => {
          await dispatch(adminUserController.delete(user.id));
          history.replace(routes.words.path);
        }}
        show={showConfirmRemove}
      />
      <Snackbar open={!!error}>
        <Alert severity="error">Misslyckades att uppdatera användaren</Alert>
      </Snackbar>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs>
          <Typography variant="h4">Användare</Typography>
        </Grid>
        <Grid item>
          <Box textAlign="right">
            <Button color="secondary" onClick={() => setShowConfirmRemove(true)}>
              Ta bort
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box p={3} display="flex">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={user.email}
                    onChange={(ev) => updateEmail(ev.currentTarget.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Åtkomst till"
                    value={parseTimestamp(user.accessUntil)?.toLocaleDateString()}
                    onChange={(ev) => updateAccessUntil(ev.currentTarget.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={user.isAdmin} onChange={(ev) => updateIsAdmin(ev.currentTarget.checked)} />
                    }
                    label="Administratör"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Organisationer</Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Namn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.memberships.map((mem) => (
                  <TableRow hover key={mem.id} style={{ cursor: "pointer" }}>
                    <TableCell component="th" scope="row">
                      {mem.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={generatePath(routes.organization, { orgId: mem.id })}>{mem.name}</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
