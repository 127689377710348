import { RpcError, RpcInvocation, useRpcDispatcher } from "cooke-rpc-react";
import { useState } from "react";

export { Link as RouterLink } from "react-router-dom";

export function useErrorDispatcher() {
  const [error, setError] = useState<RpcError | null>(null);
  const rpcDispatcher = useRpcDispatcher();

  const dispatch = async (disp: RpcInvocation<any>) => {
    try {
      return await rpcDispatcher(disp);
    } catch (error: any) {
      setError(error);
      throw error;
    }
  };

  return { dispatch, error };
}
