import { Box, Button, Container, TextField } from "@material-ui/core";
import { RpcDispatcher, RpcInvocation, sendJsonRpc } from "cooke-rpc";
import { RpcDispatcherProvider, useRpc } from "cooke-rpc-react";
import { useEffect, useState } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { config } from "./config";
import { AppContext, AppContextValue, useAppContext } from "./context";
import { FetchSessionResult, sessionController } from "./generated/rpc";
import LoggedInPage from "./LoggedInPage";
import { routes } from "./routes";
import { Email } from "./types";

function App() {
  const [appContext, setAppContext] = useState<AppContextValue>({
    session: undefined,
  });

  const [rpcDispatcher] = useState<RpcDispatcher>(
    () => (invocation: RpcInvocation<any>) =>
      sendJsonRpc(invocation, async (json: string) => {
        const response = await fetch(config.serverUrl + "/rpc", {
          method: "POST",
          mode: "cors",
          credentials: "include",
          body: json,
        });

        return await response.text();
      })
  );

  useEffect(() => {
    (async () => {
      var result: FetchSessionResult;

      while (true) {
        try {
          result = await rpcDispatcher(sessionController.fetchSession());
          break;
        } catch (error) {
          console.error("Failed to fetch session", error);
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      }

      setAppContext({
        session: result.$type === "NoSessionResult" ? null : result.data,
      });
    })();
  }, [rpcDispatcher]);

  if (appContext.session === undefined) {
    return <progress />;
  }

  return (
    <AppContext.Provider value={[appContext, setAppContext]}>
      <RpcDispatcherProvider dispatcher={rpcDispatcher}>
        <Router>
          <Box display="flex" flexDirection="column" alignItems="stretch">
            <Switch>
              <Route path={routes.login.path}>
                <LoginPage />
              </Route>
              {appContext.session ? <LoggedInPage /> : <Redirect to={routes.login.path} />}
            </Switch>
          </Box>
        </Router>
      </RpcDispatcherProvider>
    </AppContext.Provider>
  );
}

const LoginPage = (props: {}) => {
  const login = useRpc(sessionController.login);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [, setAppContext] = useAppContext();
  const history = useHistory();

  const loginError = login.error
    ? "Något gick fel"
    : login.result?.$type === "LoginFailed"
    ? "Inloggningen misslyckades"
    : null;

  return (
    <Container maxWidth="sm">
      <form
        noValidate
        onSubmit={async (ev) => {
          ev.preventDefault();
          const result = await login.invoke(username as Email, password);
          if (result.$type === "LoginSuccess") {
            setAppContext((p) => ({ ...p, session: result.sessionData }));
            history.replace("/");
          }
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Epost"
          name="email"
          autoComplete="email"
          autoFocus
          value={username}
          onChange={(ev) => setUsername(ev.target.value)}
          disabled={login.invoking}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Lösenord"
          type="password"
          id="password"
          autoComplete="current-password"
          error={!!loginError}
          helperText={loginError}
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          disabled={login.invoking}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={login.invoking}>
          Sign In
        </Button>
      </form>
    </Container>
  );
};

export default App;
