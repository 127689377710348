import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useRpcFetch } from "cooke-rpc-react";
import React from "react";
import { generatePath, useHistory } from "react-router";
import FetchStatus from "./components/FetchStatus";
import { adminUserController } from "./generated/rpc";
import { routes } from "./routes";

export function UsersTable() {
  const usersQuery = useRpcFetch(adminUserController.getUsers);
  const history = useHistory();

  if (!usersQuery.result) {
    return <FetchStatus query={usersQuery} />;
  }

  const users = usersQuery.result;
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Skapad</TableCell>
              <TableCell>Senaste aktivitet</TableCell>
              <TableCell>Åtkomst till</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                hover
                key={user.email}
                style={{ cursor: "pointer" }}
                onClick={() => history.push(generatePath(routes.user, { userId: user.id.toString() }))}
              >
                <TableCell component="th" scope="row">
                  {user.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {user.email}
                </TableCell>
                <TableCell component="td" scope="row">
                  {new Date(user.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell component="td" scope="row">
                  {new Date(user.lastLogin).toLocaleString()}
                </TableCell>
                <TableCell component="td" scope="row">
                  {new Date(user.accessUntil).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
