import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useRpcFetch } from "cooke-rpc-react";
import { Link } from "react-router-dom";
import FetchStatus from "./components/FetchStatus";
import { adminOrderController } from "./generated/rpc";

export function OrdersTable() {
  const ordersQuery = useRpcFetch(adminOrderController.getOrders);

  if (!ordersQuery.result) {
    return <FetchStatus query={ordersQuery} />;
  }

  const orders = ordersQuery.result;
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Dintero Transaction Id</TableCell>
              <TableCell>Skapad</TableCell>
              <TableCell>Uppdaterad</TableCell>
              <TableCell>Summa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow hover key={order.id} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {order.id}
                </TableCell>
                <TableCell component="td" scope="row">
                  {order.status}
                </TableCell>
                <TableCell component="td" scope="row">
                  <Link to={`/users/${order.userId}`}>
                    {order.userId} ({order.userEmail})
                  </Link>
                </TableCell>
                <TableCell component="td" scope="row">
                  <a
                    href={`https://backoffice.dintero.com/${order.dinteroAccount}/payments/transactions/${order.dinteroTransactionId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {order.dinteroTransactionId}
                  </a>
                </TableCell>
                <TableCell component="td" scope="row" title={order.createdAt ?? ""}>
                  {new Date(order.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell component="td" scope="row" title={order.updatedAt ?? ""}>
                  {order.updatedAt ? new Date(order.updatedAt).toLocaleDateString() : null}
                </TableCell>
                <TableCell component="td" scope="row">
                  {order.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
