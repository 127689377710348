import { createContext, useContext } from "react";
import { SessionData } from "./generated/rpc";

export interface AppContextValue {
  session: SessionData | null | undefined;
}

const defaultAppContextValue: AppContextValue = {
  session: null,
};

export const AppContext = createContext<
  [AppContextValue, React.Dispatch<React.SetStateAction<AppContextValue>>]
>([defaultAppContextValue, () => defaultAppContextValue]);

export const useAppContext = () => {
  return useContext(AppContext);
};
