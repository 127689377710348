import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useRpc, useRpcFetch } from "cooke-rpc-react";
import { useState } from "react";
import { generatePath, Link } from "react-router-dom";
import FetchStatus from "./components/FetchStatus";
import { ConfirmDialog } from "./ConfirmDialog";
import { adminSessionController } from "./generated/rpc";
import { routes } from "./routes";

export function SessionsTable() {
  const sessionsQuery = useRpcFetch(adminSessionController.getSessions);

  if (!sessionsQuery.result) {
    return <FetchStatus query={sessionsQuery} />;
  }

  const sessions = sessionsQuery.result;
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Användare</TableCell>
              <TableCell>Skapad</TableCell>
              <TableCell>Senaste aktivitet</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session) => (
              <TableRow hover key={session.id} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {session.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {session.user.$type === "SessionUser" ? (
                    <Link to={generatePath(routes.user, { userId: session.user.id.toString() })}>
                      {session.user.email}
                    </Link>
                  ) : (
                    <Link to={generatePath(routes.organization, { orgId: session.user.organizationId })}>
                      Gruppkonto: {session.user.id} ({session.user.organizationName})
                    </Link>
                  )}
                </TableCell>
                <TableCell component="td" scope="row">
                  {new Date(session.createdAt).toLocaleString()}
                </TableCell>
                <TableCell component="td" scope="row">
                  {new Date(session.lastActivity).toLocaleString()}
                </TableCell>
                <TableCell component="td" scope="row" align="right">
                  <RemoveAdminButton sessionId={session.id} onRemoved={() => sessionsQuery.refetch()} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function RemoveAdminButton({ sessionId, onRemoved }: { sessionId: number; onRemoved: () => void }) {
  const [showDialog, setShowDialog] = useState(false);
  const deleteSessionMutation = useRpc(adminSessionController.deleteSession);

  return (
    <>
      <Button color="secondary" onClick={() => setShowDialog(true)}>
        Ta bort
      </Button>
      <ConfirmDialog
        show={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={async () => {
          await deleteSessionMutation.invoke(sessionId);
          setShowDialog(false);
          onRemoved();
        }}
      />
    </>
  );
}
