import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useRpc, useRpcFetch } from "cooke-rpc-react";
import { useEffect, useState } from "react";
import { generatePath, Link, useHistory } from "react-router-dom";
import FetchStatus from "./components/FetchStatus";
import { adminOrganizationController } from "./generated/rpc";
import { routes } from "./routes";

export function OrganizationsTable() {
  const orgsQuery = useRpcFetch(adminOrganizationController.getAll);
  const [showAddOrg, setShowAddOrg] = useState(false);

  if (!orgsQuery.result) {
    return <FetchStatus query={orgsQuery} />;
  }

  const orgs = orgsQuery.result;
  return (
    <>
      <Box textAlign="right" mb={3}>
        <Button color="primary" variant="contained" onClick={() => setShowAddOrg(true)}>
          Lägg till organization
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Namn</TableCell>
              <TableCell>Licens</TableCell>
              <TableCell>Licens giltig till</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgs.map((org) => (
              <TableRow hover key={org.id} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  <Link to={generatePath(routes.organization, { orgId: org.id.toString() })}>{org.id}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link to={generatePath(routes.organization, { orgId: org.id.toString() })}>{org.name}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {org.license}
                </TableCell>
                <TableCell component="td" scope="row">
                  {new Date(org.licenseUntil).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddOrgDialog show={showAddOrg} onClose={() => setShowAddOrg(false)} />
    </>
  );
}

export const AddOrgDialog = (props: { show: boolean; onClose: () => void }) => {
  const [name, setName] = useState("");
  const createOrgMutation = useRpc(adminOrganizationController.create);

  const history = useHistory();

  useEffect(() => {
    setName("");
    createOrgMutation.setResult(undefined);
    createOrgMutation.setError(undefined);
  }, [props.show]);

  return (
    <Dialog open={props.show} onClose={props.onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Lägg till organisation</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Ord"
          type="text"
          fullWidth
          value={name}
          onChange={(ev) => setName(ev.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={createOrgMutation.invoking} onClick={props.onClose} color="primary">
          Avbryt
        </Button>
        <Button
          disabled={createOrgMutation.invoking}
          onClick={async () => {
            const result = await createOrgMutation.invoke({
              name,
            });

            history.push(generatePath(routes.organization, { orgId: result }));
          }}
          color="primary"
        >
          {createOrgMutation.invoking ? <CircularProgress></CircularProgress> : <>Lägg till</>}
        </Button>
      </DialogActions>
      {createOrgMutation.error ? (
        <Snackbar open>
          <Alert severity="error">{createOrgMutation.error.message || createOrgMutation.error.code}</Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
};
