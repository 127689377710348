import { Box } from "@material-ui/core";
import { useRpcFetch } from "cooke-rpc-react";
import React from "react";
import { ErrorMessage, LoadingIndicator } from "../Words";

export default function FetchStatus({
  query,
}: {
  query: ReturnType<typeof useRpcFetch>;
}) {
  if (query.error) {
    return (
      <ErrorMessage>{query.error.message || query.error.code}</ErrorMessage>
    );
  }

  return (
    <Box textAlign="center">
      <LoadingIndicator />
    </Box>
  );
}
