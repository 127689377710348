import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { RpcInvocation, useRpc, useRpcFetch } from "cooke-rpc-react";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import FetchStatus from "./components/FetchStatus";
import { ConfirmDialog } from "./ConfirmDialog";
import { adminOrganizationController, License, Organization } from "./generated/rpc";
import { routes } from "./routes";
import { Email } from "./types";
import { useErrorDispatcher } from "./utils";
import { parseTimestamp } from "./utils/parseTimestamp";

export function OrganizationView() {
  const {
    params: { orgId },
  } = useRouteMatch<any>(routes.organization)!;
  const orgQuery = useRpcFetch(adminOrganizationController.get, orgId);

  if (!orgQuery.result) {
    return <FetchStatus query={orgQuery} />;
  }

  const word = orgQuery.result;
  return <OrgForm initOrg={word} onUpdated={() => orgQuery.refetch(orgId)} />;
}

const OrgForm = ({ initOrg, onUpdated }: { initOrg: Organization; onUpdated: () => void }) => {
  const orgId = initOrg.id;

  const [org, setOrg] = useState(initOrg);
  useEffect(() => {
    setOrg(initOrg);
  }, [initOrg]);

  const { dispatch, error } = useErrorDispatcher();

  const useUpdateCallback = <TState extends any[]>(
    stateCb: (...val: TState) => void,
    invoke: (...val: TState) => RpcInvocation<any>
  ) => {
    const debouncedInvoke = debounce((...val: TState) => dispatch(invoke(...val)), 500);
    return useCallback(
      (...val: TState) => {
        stateCb(...val);
        debouncedInvoke(...val);
      },
      [orgId]
    );
  };

  const updateName = useUpdateCallback(
    (name: string) => setOrg((p) => ({ ...p, name })),
    (name: string) => adminOrganizationController.update(orgId, { name })
  );

  const updateLicense = useUpdateCallback(
    (license: License) => setOrg((p) => ({ ...p, license })),
    (license: License) => adminOrganizationController.update(orgId, { license: license || null })
  );

  const updateAccessUntil = useUpdateCallback(
    (licenseUntil: string) => setOrg((p) => ({ ...p, licenseUntil })),
    (licenseUntil: string) => adminOrganizationController.update(orgId, { licenseUntil: licenseUntil })
  );

  const updateEmail = useUpdateCallback(
    (email: Email) => setOrg((p) => ({ ...p, email })),
    (email: Email) => adminOrganizationController.update(orgId, { email })
  );

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const history = useHistory();

  return (
    <>
      <ConfirmDialog
        onCancel={() => setShowConfirmRemove(false)}
        onConfirm={async () => {
          await dispatch(adminOrganizationController.delete(orgId));
          history.replace(routes.organizations);
        }}
        show={showConfirmRemove}
      />
      <Snackbar open={!!error}>
        <Alert severity="error">Misslyckades att uppdatera</Alert>
      </Snackbar>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs>
          <Typography variant="h4">{org.name}</Typography>
        </Grid>
        <Grid item>
          <Box textAlign="right">
            <Button color="secondary" onClick={() => setShowConfirmRemove(true)}>
              Ta bort organisation
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          Antal sessioner: {org.numberOfSessions}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box p={3} display="flex">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={org.name}
                    onChange={(ev) => updateName(ev.currentTarget.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={org.email ?? ""}
                    type="email"
                    onChange={(ev) => updateEmail(ev.currentTarget.value as Email)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Licens till"
                    value={parseTimestamp(org.licenseUntil)?.toLocaleDateString()}
                    onChange={(ev) => updateAccessUntil(ev.currentTarget.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="license-label">Licens</InputLabel>
                    <Select
                      labelId="license-label"
                      id="license-select"
                      value={org.license ?? ""}
                      onChange={(ev) => updateLicense(ev.target.value as License)}
                      label="Licens"
                    >
                      <MenuItem value="">
                        <em>Ingen</em>
                      </MenuItem>
                      {Object.values(License).map((license) => (
                        <MenuItem key={license} value={license}>
                          {license}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Administratörer</Typography>
          <AddAdminButton orgId={orgId} onAdded={onUpdated} />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Användar Id</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Skapad</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {org.members.map((member) => (
                  <TableRow hover key={member.email} style={{ cursor: "pointer" }}>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={
                        member.userId
                          ? () => history.push(generatePath(routes.user, { userId: member.userId! }))
                          : undefined
                      }
                    >
                      {member.userId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {member.email}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {new Date(member.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell component="td" scope="row" align="right">
                      <RemoveAdminButton orgId={orgId} email={member.email} onRemoved={onUpdated} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Gruppkonton</Typography>
          <AddGroupAccountButton orgId={orgId} onAdded={onUpdated} />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Användarnamn</TableCell>
                  <TableCell>Lösenord</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {org.groupAccounts.map((account) => (
                  <TableRow hover key={account.id} style={{ cursor: "pointer" }}>
                    <TableCell component="th" scope="row">
                      {account.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {account.username}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {account.code}
                    </TableCell>
                    <TableCell component="td" scope="row" align="right">
                      <RemoveGroupAccountButton id={account.id} onRemoved={onUpdated} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

function AddAdminButton({ orgId, onAdded }: { orgId: string; onAdded: () => void }) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Box textAlign="right" mb={3}>
        <Button color="primary" variant="contained" onClick={() => setShowDialog(true)}>
          Lägg till administratör
        </Button>
      </Box>
      <AddAdminDialog
        show={showDialog}
        onClose={() => {
          setShowDialog(false);
          onAdded();
        }}
        orgId={orgId}
      />
    </>
  );
}

export const AddAdminDialog = (props: { show: boolean; onClose: () => void; orgId: string }) => {
  const [email, setEmail] = useState("");
  const addMemberMutation = useRpc(adminOrganizationController.addMember);

  useEffect(() => {
    setEmail("");
    addMemberMutation.setResult(undefined);
    addMemberMutation.setError(undefined);
  }, [props.show]);

  return (
    <Dialog open={props.show} onClose={props.onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Lägg till administratör</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Admin email"
          type="text"
          fullWidth
          value={email}
          onChange={(ev) => setEmail(ev.currentTarget.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={addMemberMutation.invoking} onClick={props.onClose} color="primary">
          Avbryt
        </Button>
        <Button
          disabled={addMemberMutation.invoking}
          onClick={async () => {
            await addMemberMutation.invoke(props.orgId, email as Email);
            props.onClose();
          }}
          color="primary"
        >
          {addMemberMutation.invoking ? <CircularProgress></CircularProgress> : <>Lägg till</>}
        </Button>
      </DialogActions>
      {addMemberMutation.error ? (
        <Snackbar open>
          <Alert severity="error">{addMemberMutation.error.message || addMemberMutation.error.code}</Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
};

function RemoveAdminButton({ orgId, email, onRemoved }: { orgId: string; email: string; onRemoved: () => void }) {
  const [showDialog, setShowDialog] = useState(false);
  const removeMemberMutation = useRpc(adminOrganizationController.removeMember);

  return (
    <>
      <Button color="secondary" onClick={() => setShowDialog(true)}>
        Ta bort
      </Button>
      <ConfirmDialog
        show={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={async () => {
          await removeMemberMutation.invoke(orgId, email as Email);
          setShowDialog(false);
          onRemoved();
        }}
      />
    </>
  );
}

function AddGroupAccountButton({ orgId, onAdded }: { orgId: string; onAdded: () => void }) {
  const createGroupAccountMutation = useRpc(adminOrganizationController.createGroupAccount);

  return (
    <>
      <Box textAlign="right" mb={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={async () => {
            await createGroupAccountMutation.invoke(orgId);
            onAdded();
          }}
        >
          Lägg till gruppkonto
        </Button>
      </Box>
    </>
  );
}

function RemoveGroupAccountButton({ id, onRemoved }: { id: number; onRemoved: () => void }) {
  const [showDialog, setShowDialog] = useState(false);
  const removeGroupAccountMutation = useRpc(adminOrganizationController.deleteGroupAccount);

  return (
    <>
      <Button color="secondary" onClick={() => setShowDialog(true)}>
        Ta bort
      </Button>
      <ConfirmDialog
        show={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={async () => {
          await removeGroupAccountMutation.invoke(id);
          onRemoved();
        }}
      />
    </>
  );
}
