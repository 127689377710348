import { Box } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Editor, RawDraftContentState } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw } from "draft-js";

export const WysiwygEditor = (props: { initialHtml: string; onDeboucedUpdate: (html: string) => void }) => {
  const initialContentState = useMemo(
    () => convertToRaw(ContentState.createFromBlockArray(htmlToDraft(props.initialHtml).contentBlocks)),
    [props.initialHtml]
  );

  const updateExplanation = useCallback(
    debounce((state: RawDraftContentState) => props.onDeboucedUpdate(draftToHtml(state)), 500),
    [props.onDeboucedUpdate]
  );

  return (
    <Box border="1px solid #ddd">
      <Editor
        initialContentState={initialContentState}
        onChange={updateExplanation}
        editorStyle={{ height: 150 }}
        stripPastedStyles
        toolbar={{
          options: ["inline", "list", "textAlign", "colorPicker", "link", "remove", "history"],
          inline: { inDropdown: false },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </Box>
  );
};
